@import "./variables.styles.scss";
@import "./mixins.scss";

h1,
h2,
h3,
h4,
.collection-title {
  font-family: "Cormorant", serif;
  font-weight: 300;
}

h1 {
  font-size: 7.6vw;
  color: $color-text-primary;

  @include respond(phone) {
    font-size: 13.2vw;
  }
}

.title {
  font-size: 0.8vw;
  font-weight: 400;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.3em;

  @include respond(phone) {
    font-size: 2.6vw;
  }

  &__sub {
    color: $color-secondary;
    line-height: 100%;
    font-size: 5vw;
    line-height: 90%;
    margin-bottom: 1vw;
    font-weight: 200;
    position: relative;
    @include respond(phone) {
      font-size: $font-size-large-mobile;
    }
    &::after {
      content: " ";
      display: block;
      position: absolute;
      width: 6vw;
      height: 1px;
      background-color: $color-secondary;
      bottom: -2vw;

      @include respond(phone) {
        width: 18vw;
        bottom: -4vw;
      }
    }
    span {
      font-style: italic;
    }
  }
}

.collection-title {
  font-size: $font-size-large;

  color: $color-primary;
  font-weight: 400;
  font-family: "Cormorant", serif;

  @include respond(phone) {
    font-size: 14vw;
  }
}
