@import "../../scss/variables.styles.scss";
@import "../../scss/mixins.scss";

.side-by-side-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .image-container {
    flex: 2;

    img {
      width: 35vw;
      height: 20vw;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      @include respond(phone) {
          width: 100%;
          height: 75vw;
        }
    }
  }

  .description-container {
    flex: 1;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 12px;
    box-shadow: none;
    text-align: left;

    .title__sub {
      font-size: 1.2em;
      color: #333;
      line-height: 1.5;
      margin-bottom: 10px;

      span {
        font-weight: bold;
        color: #3498db;
        display: block;
        margin-bottom: 8px;
      }
    }

    .service-list {
      list-style: none;
      padding: 0;
      font-size: 1em;
      margin: 10px 0;

      li {
        padding: 8px 0;
      }
    }
  }

  @media (max-width: 768px) {
    .description-container {
      padding: 10px;

      .title__sub {
        font-size: 1em;
      }

      .service-list {
        font-size: 0.9em;
      }
    }
  }
}