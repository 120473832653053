@import "../../scss/mixins.scss";
@import "../../scss/variables.styles.scss";

.cart-preview {
  position: fixed;
  height: 100vh;
  width: 34vw;
  right: 0;
  top: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  background-color: white;

  @include respond(phone) {
    width: 80vw;
  }
  &__block--top {
    display: flex;
    margin: 5vh 1vw 1vh 1vw;
    align-items: center;
    padding: 0 2rem;

    .cart-title {
      font-size: 1.8vw;
      font-family: "cormorant", sans-serif;

      @include respond(phone) {
        font-size: 5.8vw;
      }
    }

    .btn--close {
      font-size: 1.6vw;
      margin-left: auto;
      cursor: pointer;
      @include respond(phone) {
        font-size: 6.6vw;
      }
    }
  }
  &__block--bottom {
    position: relative;
    margin: 6vh 0;
    padding: 0 2vw;
    @include respond(phone) {
      margin: 1vh 0;
    }
    .promo-text {
      text-align: center;
      font-size: 1.1vw;
      @include respond(phone) {
        font-size: 3.2vw;
        padding: 0 2vw;
      }
    }
    button {
      position: absolute;
      left: 25%;
      margin: 2vh auto 0 auto;
      font-size: 0.8vw;
      background-color: $color-primary;
      color: white;
      width: 50%;
      height: 3.2vw;
      border: none;
      @include respond(phone) {
        font-size: 2.2vw;
        height: 7.2vw;
      }
    }
  }

  .cart-items {
    min-height: 2.4rem;
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    max-height: 60vh;
    margin: 1vh 0;
    overflow-y: scroll;

    .empty-message {
      margin: 20vh auto;
      font-size: 1.6vw;
      @include respond(phone) {
        font-size: 4.6vw;
      }
    }
  }
}
