@import "../../scss/variables.styles.scss";
@import "../../scss/mixins.scss";

.header {
  width: 100vw;
  height: 8vw;
  position: fixed;
  background-color: transparent;
  top: 0;
  display: flex;
  z-index: 8;
  padding: 0 6%;
  transition: height ease-in-out 0.3s;
  align-items: center;

  // backdrop-filter: blur(18px);
  // -webkit-backdrop-filter: blur(18px);

  @include respond(phone) {
    height: 20vw;
    height: 17vh;
    padding-top: 21px;
  }

  &__logo {
    width: 10vw;
    height: auto;

    @include respond(phone) {
      width: 32vw;
    }
  }
  &__right {
    display: inherit;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    .hamburger {
      z-index: 9;
      &:hover {
        cursor: pointer;
      }
      & .line {
        width: 2.2vw;
        height: 10px;
        background-color: #97d41f;
        display: block;

        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        @include respond(phone) {
          width: 8vw;
        }

        &:nth-child(2) {
          margin: 0.6vw 0;

          @include respond(phone) {
            margin: 1.4vw 0;
          }
        }
      }
    }
  }

  &__item {
    font-size: 1vw;
    color: $color-white-secondary;
    font-weight: 500;
    cursor: pointer;
    margin-right: 2vw;
    @include respond(tab-port) {
      margin-right: 4vw;
    }
    @include respond(phone) {
      display: none;
    }
  }
}

#hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(0.7vw) rotate(45deg);
  -ms-transform: translateY(0.7vw) rotate(45deg);
  -o-transform: translateY(0.7vw) rotate(45deg);
  transform: translateY(0.7vw) rotate(45deg);

  @include respond(phone) {
    -webkit-transform: translateY(2.7vw) rotate(45deg);
    -ms-transform: translateY(2.7vw) rotate(45deg);
    -o-transform: translateY(2.7vw) rotate(45deg);
    transform: translateY(2.7vw) rotate(45deg);
  }
}

#hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-0.7vw) rotate(-45deg);
  -ms-transform: translateY(-0.7vw) rotate(-45deg);
  -o-transform: translateY(-0.7vw) rotate(-45deg);
  transform: translateY(-0.7vw) rotate(-45deg);
  @include respond(phone) {
    -webkit-transform: translateY(-0.6vw) rotate(-45deg);
    -ms-transform: translateY(-0.6vw) rotate(-45deg);
    -o-transform: translateY(-0.6vw) rotate(-45deg);
    transform: translateY(-0.6vw) rotate(-45deg);
  }
}
.menu-logo {
  width: 12vw;
  @include respond(phone) {
    width: 32vw;
  }
}
.menu-nav {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;
  z-index: 7;
  top: 0;
  left: 0;
  height: 100vh;
  visibility: hidden;
  // background-color:rosybrown;

  &__left {
    width: 40%;
    //height: fit-content;
    background-color: antiquewhite;
    position: relative;
    overflow: hidden;
    z-index: 2;
    height: 0;

    @include respond(phone) {
      display: none;
    }

    &--imgs {
      width: 100%;
      height: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
      // object-fit: cover;
    }
    &--img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__right {
    width: 60%;
    height: 0%;
    background-color: white;
    position: relative;

    @include respond(phone) {
      width: 100%;
    }
  }

  &__head {
    left: 10vw;
    top: 4.5vw;
    z-index: 7;
    height: 1.25vw;
    position: absolute;
    visibility: hidden;
    @include respond(phone) {
      top: 12vw;
    }
  }

  &__main {
    width: 25%;
    margin: 15vw 10vw;
    visibility: hidden;

    @include respond(phone) {
      margin: 53vw 10vw 0 10vw;
    }
  }
  &__link {
    color: $color-black;
    font-family: "Cormorant", serif;
    font-size: 4vw;

    font-weight: 200;
    cursor: pointer;
    width: fit-content;

    @include respond(phone) {
      font-size: 15vw;
      font-weight: 300;
    }
    @include respond(desktop) {
      &:hover {
        font-style: italic;
        color: $color-primary;
      }
    }
  }
}
