@import "../../scss/variables.styles.scss";
@import "../../scss//mixins.scss";
.product-modal {
  &__container {
    position: fixed;
    z-index: 20;
    width: 100%;
    height: 100vh;
    top: 0;
    transform: translateX(-50%);
    left: 50%;
    display: block;
    background-color: #3a3a3a80;
    backdrop-filter: blur(2px);
    animation: display-modal 0.5s ease-in-out;
  }

  &__modal {
    background-color: whitesmoke;
    width: 70%;
    height: 80vh;
    z-index: 21;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    display: flex;
    align-items: center;
    padding: 4vw;
    animation: slide-up 0.2s;
    animation-delay: 0.4s;
    @include respond(tab-land) {
      width: 80%;
      height: 50rem;
      padding: 3vw;
    }
    @include respond(tab-port) {
      flex-direction: column;
      justify-content: center;
    }
    @include respond(phone) {
      width: 95%;
    }
  }
  &__img {
    width: 24vw;
    height: 70%;
    @include respond(tab-land) {
      height: 50%;
    }
    @include respond(tab-port) {
      width: 30vw;
    }
    @include respond(phone) {
      width: 50vw;
      height: auto;
    }
  }
  &__close-btn {
    top: -6vw;
    right: -5vw;
    position: absolute;
    color: white;
    border: none;
    background-color: transparent;
    font-size: 4vw;
    font-family: Libre Franklin, "arial-sans";
    font-weight: lighter;
    padding: 2vw;
    cursor: pointer;
    @include respond(phone) {
      right: -1vw;
      top: -12vw;
      font-size: 3rem;
    }
  }
  &__details {
    padding-left: 3vw;
    @include respond(phone) {
      padding: unset;
    }
  }
  &__product-name {
    font-size: 1.6vw;
    font-weight: 500;
    font-family: Cormorant, "sans-serif";
    margin-bottom: 1.4vw;
    @include respond(tab-land) {
      font-size: 2.2rem;
    }
    @include respond(tab-port) {
      text-align: center;
      margin: 2rem 0;
    }
  }
  &__description {
    font-size: 1.2vw;
    margin-bottom: 2vw;
    text-align: justify;
    @include respond(tab-land) {
      font-size: 1.6rem;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    font-size: 1.8vw;
    align-items: flex-end;
    align-items: center;
    justify-content: space-between;
    @include respond(tab-port) {
      margin-top: 3rem;
    }
    .add-to-cart {
      background-color: $color-primary;
      padding: 1.4vw 4vw;
      border: none;
      color: white;
      font-family: Libre Franklin, "serif";
      cursor: pointer;
      width: fit-content;
      margin-left: 1vw;
      @include respond(tab-port) {
        padding: 1.4rem 2.8rem;
      }
    }
  }

  &__price {
    font-family: Cormorant, "sans-serif";
    font-size: 3vw;
    @include respond(phone) {
      font-size: 4rem;
      margin-bottom: 1rem;
    }
  }
}

@keyframes display-modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
