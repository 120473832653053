.update-images-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 100px;
  font-family: Arial, sans-serif;

  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }

  .section-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    span {
      font-size: 18px;
      font-weight: bold;
    }

    select {
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }

  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }

  .image-wrapper {
    text-align: center;
    border: 1px solid #eaeaea;
    padding: 15px;
    border-radius: 10px;
    background: #fafafa;

    h3 {
      font-size: 16px;
      margin-bottom: 10px;
      color: #555;
    }

    .image-preview {
      max-width: 100%;
      height: auto;
      border: 1px solid #ddd;
      border-radius: 5px;
      object-fit: cover;
    }

    input {
      margin-top: 10px;
      font-size: 14px;
    }

    .preview-wrapper {
      margin-top: 10px;

      h4 {
        font-size: 14px;
        color: #555;
      }
    }
  }

  .upload-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
}