@import "../scss/variables.styles.scss";
@import "../scss//mixins.scss";

.section__about {
  padding: 12vw 0 12vw 0;
  min-height: 70vh;
  max-width: 100vw;
  background-color: $color-bg;

  @include respond(tab-land) {
    min-height: 70vh;
    padding: 30vw 0 6vw 0;
  }

  @include respond(tab-port) {
    min-height: 56vh;
    padding: 18vw 0 0 0;
  }

  @include respond(phone) {
    padding: 10vw 0 20vw 0;
  }

  .about__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 15%;

    @include respond(phone) {
      flex-direction: column-reverse;
    }

    &--text {
      width: 16vw;

      @include respond(phone) {
        width: unset;
        margin-top: 16vw;
        margin-right: auto;
      }
    }

    .title {
      color: $color-secondary;
      margin-bottom: 2vw;

      @include respond(phone) {
        font-size: 2.8vw;
        margin-bottom: 4vw;
      }
    }

    .paragraph {
      color: $color-secondary;
      font-size: $font-size-small;
      line-height: 1.6em;
      margin-top: 3vw;

      @include respond(phone) {
        margin-top: 8vw;
        font-size: $font-size-small-mobile;
      }
    }

    &--img {
      margin-left: auto;
      width: fit-content;
      position: relative;

      @include respond(phone) {
        margin-left: 16vw;
      }
    }

    .img {
      &-1 {
        width: 35vw;
        height: 40vw;

        @include respond(phone) {
          height: 80vw;
          width: 55vw;
        }
      }

      &-2__banner {
        position: absolute;
        width: 16vw;
        height: 24vw;
        left: -25%;
        bottom: 30%;
        z-index: 2;
        overflow: hidden;

        @include respond(phone) {
          height: 42vw;
          width: 30vw;
        }
      }

      &-2 {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.section__video {
  position: relative;
  height: fit-content;
  height: 50vw;
  overflow: hidden;
  // margin-bottom: 10vw;
  // margin-top: -16vw;

  .video__img {
    width: 100vw;
    object-fit: cover;
    height: auto;
  }

  .blur {
    width: 6vw;
    height: 6vw;
    border-radius: 50%;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 3;

    background-color: #6c4f235c;

    //-webkit-backdrop-filter: blur(18px);
    @include respond(phone) {
      width: 10vw;
      height: 10vw;
    }

    .play {
      width: 83%;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
}

.section__ethos {
  // padding-bottom: 10vw;

  .ethos__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15% 15%;
      background: white;

    @include respond(phone) {
      flex-direction: column;
    }

    &--text {
      width: 16vw;
      margin-left: auto;

      @include respond(phone) {
        width: unset;
        margin-top: 16vw;
        margin-right: auto;
      }
    }

    .title {
      color: $color-secondary;
      margin-bottom: 2vw;

      @include respond(phone) {
        font-size: 2.8vw;
      }
    }

    .paragraph {
      color: $color-secondary;
      font-size: $font-size-small;
      line-height: 1.6em;
      // width: 20vw;
      margin-top: 3vw;

      @include respond(phone) {
        margin-top: 8vw;
        font-size: $font-size-small-mobile;
      }
    }

    &--img {
      margin-right: auto;
      width: fit-content;
      position: relative;

      @include respond(phone) {
        margin-right: 16vw;
      }

      .img {
        &-1 {
          width: 35vw;
          height: 40vw;
          position: relative;

          @include respond(phone) {
            height: 80vw;
            width: 55vw;
          }
        }

        &-2__banner {
          position: absolute;
          width: 16vw;
          height: 24vw;
          right: -25%;
          bottom: 30%;
          z-index: 2;
          overflow: hidden;

          @include respond(phone) {
            height: 42vw;
            width: 30vw;
          }
        }

        &-2 {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: scale(1.2);
        }
      }
    }
  }
}

.section__newsletter {
  background-color: white;
  padding: 8vw;
  text-align: center;
  @include respond(phone) {
    padding: 12vw 8vw;
  }

  .title {
    &__sub {
      width: 50vw;
      margin: 2.4vw auto 0 auto;
      line-height: 110%;

      &::after {
        all: unset;
      }

      @include respond(phone) {
        width: unset;
      }
    }
  }

  form {
    margin-top: 1.2vw;
  }

  input {
    width: 40%;
    margin: 2vw;
    font-size: 1.6vw;

    @include respond(phone) {
      width: 80%;
      margin: 6vw auto;
      display: block;
      font-size: $font-size-small-mobile;
    }
  }

  button {
    border: none;
    background-color: $color-primary;
    color: white;
    width: 14vw;
    height: 4vw;

    @include respond(phone) {
      width: 40vw;
      height: 10vw;
    }
  }
}
