@import "../../scss/mixins.scss";

.funslider {
  width: 100vw;
  @include respond(phone) {
    width: 198vw;
  }

  &__img {
    height: 100%;
    background-size: cover;
    margin: 0 auto;
    width: fit-content;
  }

  .largest {
    width: 20vw;
    height: 32vw;

    @include respond(tab-land) {
      width: 20vw;
      height: 34vw;
    }
    @include respond(phone) {
      width: 40vw;
      height: 74vw;
    }
  }

  .large {
    width: 20vw;
    height: 28vw;
    @include respond(tab-land) {
      width: 22vw;
      height: 28vw;
    }
    @include respond(phone) {
      width: 40vw;
      height: 60vw;
    }
  }

  .small {
    width: 18vw;
    height: 18vw;
    @include respond(tab-land) {
      width: 20vw;
      height: 20vw;
    }
    @include respond(phone) {
      width: 40vw;
      height: 40vw;
    }
  }
}

.slick-track {
  display: flex;
  align-items: center;
}
