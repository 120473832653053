@import "./mixins.scss";

$color-primary: #6c4f23;
$color-secondary: #191919;
$color-dark-grey: #4a4a4a;
$color-grey: #767676;
$color-transparent: rgba(254, 245, 231, 0.1);

$color-black: #1f1f1f;

$color-white-secondary: #eeeeee;
$color-bg: #f3f2f2;
$color-text-primary: #000;
// $color-secondary: #062a35;
$font-size-button: 1vw;
$font-size-button-mobile: 4vw;

$font-size-small: 1.2vw;
$font-size-small-mobile: 3.8vw;

$font-size-medium: 1.8vw;
$font-size-medium-mobile: 4.8vw;

$font-size-large: 4.6vw;
$font-size-large-mobile: 10vw;
