@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


$primary-color: #e67e22;
$secondary-color: #3498db;
$cta-color: #2ecc71;
$background-gradient: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
$font-family: 'Roboto', sans-serif;

.recliner-services {
  font-family: $font-family;
  color: #333;
  text-align: center;
  background-color: #f9f9f9;
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: auto;

  .recheader {
    background: $background-gradient;
    padding: 30px;
    border-radius: 20px;
    color: white;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    text-align: left;

    h1 {
      font-size: 3em;
      margin-bottom: 20px;
      text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
      line-height: 1.2;
    }

    p {
      font-size: 1.5em;
      margin-top: 10px;
    }
  }

  .services,
  .about,
  .contact {
    margin: 40px 0;

    h2 {
      font-size: 2.5em;
      color: $secondary-color;
      margin-bottom: 30px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    ul.service-list {
      list-style: none;
      padding: 0;
      font-size: 1.5em;
      margin: 20px auto;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 20px;

      li {
        padding: 20px;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.05);
          box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
        }

         // Style the <a> tag inside <li>
        a {
          text-decoration: none; // Removes the underline
          color: #333;           // Sets the text color
          display: block;        // Makes the entire <li> clickable (optional)
          padding: 10px;         // Adds padding around the text

          &:hover {
            color: #007bff; // Changes color on hover (or any color you like)
          }
        }
      }
    }

    p {
      font-size: 1.5em;
      margin: 20px 0;
      line-height: 1.6;
    }
  }

  .recfooter {
    margin-top: 40px;

    .cta-button {
      background-color: $cta-color;
      color: white;
      padding: 20px 40px;
      font-size: 1.8em;
      border: none;
      border-radius: 30px;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s ease;
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);

      &:hover {
        background-color: darken($cta-color, 10%);
        transform: translateY(-7px);
      }
    }
  }

  @media (max-width: 768px) {
    padding: 20px;

    .recheader {
      h1 {
        font-size: 2.2em;
      }

      p {
        font-size: 1.2em;
      }
    }

    .services,
    .about,
    .contact {
      h2 {
        font-size: 2em;
      }
    }

    .recfooter .cta-button {
      font-size: 1.4em;
      padding: 15px 30px;
    }
  }
}