@import "../../scss/variables.styles.scss";
@import "../../scss/mixins.scss";

.signup {
  width: 100vw;
  height: 100vh;
  background-color: $color-bg;
  display: flex;
  flex-direction: row;

  @include respond(phone) {
    flex-direction: column;
  }
  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50%;
    @include respond(phone) {
      width: 100%;
      height: 100%;
    }
    .header__logo {
      position: absolute;
      top: 7%;
      left: 6vw;
      width: 10vw;
      height: auto;
      @include respond(phone) {
        width: 30vw;
      }
    }
    h2 {
      text-align: left;
      font-size: 2vw;
      margin-bottom: 2vw;
      @include respond(phone) {
        font-size: 6.8vw;
        margin-bottom: 4vw;
      }
    }

    .group {
      width: 50%;
      // background-color: brown;
      text-align: center;
      color: $color-black;
      margin-top: 6vw;
      @include respond(phone) {
        width: 70%;
      }
      h2 {
        color: $color-black;
      }

      button {
        height: 3vw;
        font-size: 1vw;
        @include respond(phone) {
          font-size: 3vw;
          height: 8vw;
        }

        &:first-of-type {
          margin-bottom: 1rem;

          &:hover {
            background-color: $color-primary;
            border: 1px solid $color-primary;
            color: white;
          }
        }
        &:last-of-type {
          margin-top: 1rem;
        }
      }
    }
  }

  .signup__cta {
    margin-top: 2vw;
    @include respond(phone) {
      margin-top: 6vw;
      font-size: 3.6vw;
    }
    & > a {
      color: $color-primary;
      border-bottom: 1px solid $color-primary;
    }
  }
  .right {
    height: 100%;
    margin-left: auto;

    @include respond(phone) {
      height: 0;
      width: 100% !important;
      background-position-y: 75%;
    }
  }
}
