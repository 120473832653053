@import "../../scss/variables.styles.scss";
@import "../../scss/mixins.scss";

.whatsapp-cta {
  text-align: center;
  margin-bottom: 10px; // Adds space between buttons

  .cta-button {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px; // Slightly reduced padding for better fit on smaller screens
    background-color: #25D366;
    color: white;
    font-size: 1rem; // Reduced font size
    font-weight: bold;
    border-radius: 50px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    img {
      width: 20px; // Reduced icon size
      height: 20px;
      margin-right: 8px;
    }

    &:hover {
      background-color: #1EBB60;
    }
  }

  // Mobile adjustments
  @media (max-width: 768px) {
    .cta-button {
      width: 100%; // Full-width on mobile
      justify-content: center;
      font-size: 0.9rem; // Slightly smaller font
      padding: 8px 12px;

      img {
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
    }
  }
}
