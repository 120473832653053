@import "../../scss/mixins.scss";

.cart-item {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e8e8e8;
  margin: 1rem 3rem;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  img {
    width: 8.4vw;
    height: 8.4vw;
    display: inline-block;

    @include respond(phone) {
      width: 20vw;
      height: 20vw;
    }
  }

  .item {
    &-details {
      align-items: center;
      width: 100%;
      padding: 1vw;
      &__block {
        display: flex;
        justify-content: space-between;
      }
    }

    &-name {
      font-size: 1.6vw;
      font-family: "Cormorant", sans-serif;
      @include respond(phone) {
        font-size: 4.6vw;
      }
    }

    &-category {
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-weight: 600;
      font-size: 0.6vw;
      margin: 1vw 0;
      @include respond(phone) {
        font-size: 1.8vw;
      }
    }

    &-price {
      height: 1.2rem;
      line-height: 2;
      font-size: 1vw;
      @include respond(phone) {
        font-size: 3.4vw;
      }
    }

    &-quantity {
      font-size: 1vw;
      line-height: 2.2;
      @include respond(phone) {
        font-size: 2.6vw;
      }
    }
  }

  .btn-remove {
    cursor: pointer;
    line-height: 3.2;
    font-size: 0.8vw;
    position: relative;
    @include respond(phone) {
      line-height: 2.2;
      font-size: 2.8vw;
    }
  }
}
