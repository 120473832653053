html {
  overflow-x: hidden;
  overflow-y: scroll;
}

html.touch *:hover {
  all: unset !important;
}
body {
  margin: 0;
  overflow-x: hidden;

  font-family: "Libre Franklin", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f2f2;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
