@import "../../scss/variables.styles.scss";
@import "../../scss/mixins.scss";

.before-after-slider {
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: auto;
  cursor: ew-resize;

  .image-container {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    .after-image {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      height: 100%;

      img {
        display: block;
      }
    }

    .slider-handle {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 3px;
      background-color: #fff;
      z-index: 10;

      .handle-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #fff;
        border: 3px solid #000;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

@media (max-width: 768px) {
  .before-after-slider {
    max-width: 100%;
  }
}