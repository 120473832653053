@import "../../scss/mixins.scss";
@import "../../scss/variables.styles.scss";

.logIn {
  width: 100vw;
  height: 100vh;
  background-color: $color-bg;
  display: flex;
  flex-direction: row;
  @include respond(phone) {
    flex-direction: column;
  }
  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color-black;
    width: 54%;
    position: relative;

    @include respond(phone) {
      width: 100%;
      height: 100%;
    }
    .header__logo {
      position: absolute;
      top: 7%;
      left: 6vw;
      width: 10vw;
      height: auto;
      @include respond(phone) {
        width: 30vw;
      }
    }

    h2 {
      text-align: left;
      font-size: 2vw;
      margin-bottom: 1.4vw;
      color: $color-black;
      @include respond(phone) {
        font-size: 6.8vw;
        margin-bottom: 4vw;
      }
    }

    .group {
      width: 50%;
      // background-color: brown;
      text-align: center;
      color: $color-black;
      margin: 1vw 0 0 0;

      @include respond(phone) {
        width: 70%;
      }
      button {
        height: 3vw;
        font-size: 1vw;
        @include respond(phone) {
          height: 8vw;
          font-size: 3vw;
        }
        &:first-of-type:hover {
          background-color: $color-primary;
          border: 1px solid $color-primary;
          color: white;
        }
      }
    }
    .login__cta {
      margin-top: 2vw;
      @include respond(phone) {
        margin-top: 6vw;
        font-size: 3.6vw;
      }
      & > a {
        color: $color-primary;
        border-bottom: 1px solid $color-primary;
      }
    }
  }

  .right {
    height: 100%;
    @include respond(phone) {
      width: 100% !important;
      height: 0;
      background-position-y: 75%;
    }
  }
}
