* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
a {
  text-decoration: none;
  color: white;
}

li{
  list-style: none;
}
