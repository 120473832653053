@import "../../scss/variables.styles.scss";
@import "../../scss/mixins.scss";

.header-container {
  margin: 0 calc(100vw / 20 * 4);
  padding-top: 12vw;

  @include respond(phone) {
    margin: 0 calc(100vw / 12 * 1);
    border-bottom: 1px solid black;
    padding-bottom: 1vw;
    padding-top: 36vw;
  }
}

.shop__title {
  font-size: 5.4vw;
  color: $color-text-primary;
  text-transform: none;

  @include respond(phone) {
    margin-top: 3vw;
    font-size: 13vw;
  }

  &--icon {
    display: none;
    position: relative;
    transform: translateX(-100%);
    img {
      width: 8vw;
      height: 8vw;
    }
    @include respond(phone) {
      position: absolute;
      right: 0;

      font-size: 13vw;
      margin-right: 0;
      vertical-align: middle;
      display: inline-block;
    }
  }
  &--sub {
    color: $color-text-primary;
    line-height: 100%;
    text-transform: uppercase;
    font-size: 0.8vw;
    letter-spacing: 0.3em;
    line-height: 90%;
    font-family: "Libre Franklin", sans-serif;
    margin-bottom: 2.4vw;

    @include respond(phone) {
      font-size: 2.6vw;
      font-weight: 600;
    }
  }
}
