@import "../../scss/mixins.scss";

.shopping-icon {
  position: relative;
}
.cart-icon {
  position: relative;
  @include respond(phone) {
    display: block;
  }
  img {
    width: 1.6vw;
    height: auto;
    @include respond(phone) {
      width: 5.8vw;
      margin-right: 3vw;
    }
  }
}

.pos-abs {
  width: 1.2vw;
  height: 1.2vw;
  position: absolute;
  border-radius: 50%;
  background-color: #6c4f23;
  top: -0.5vw;
  right: -0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;

  @include respond(phone) {
    top: -1.2vw;
    right: 2vw;
    width: 3.4vw;
    height: 3.4vw;
  }

  & span {
    font-size: 0.8vw;
    color: white;
    text-align: center;

    @include respond(phone) {
      font-size: 2.2vw;
    }
  }
}
