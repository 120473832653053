@import "../../scss/variables.styles.scss";
@import "../../scss/mixins.scss";

.furniture-item {
  position: relative;
  width: calc(100vw / 20 * 3);
  height: 20vw;
  margin: 0 2.5vw 4vw 2.5vw;
  cursor: pointer;

  @include respond(phone) {
    width: calc(100vw / 12 * 4.5);
    height: 70vw;
    margin: unset;

    &:nth-child(odd) {
      margin-right: calc(100vw / 12 * 0.5);
    }

    &:nth-child(even) {
      margin-left: calc(100vw / 12 * 0.5);
    }
  }
  @include respond(desktop) {
    &:hover {
      .furniture-item__img {
        opacity: 0.8;
      }
    }
    .button-container .custom-button {
      opacity: 1;
      bottom: 0;
    }
  }
  &:nth-child(4n + 1) {
    margin-left: 0;

    @include respond(phone) {
      margin-left: unset;
    }
  }
  &:nth-child(4n + 4) {
    margin-right: 0;
    @include respond(phone) {
      margin-right: unset;
    }
  }
  &__img {
    height: 15vw;
    background-size: cover;

    @include respond(phone) {
      height: 37vw;
    }
  }

  &__category {
    color: $color-dark-grey;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin-top: 10%;
  }

  &__details {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 6%;
    font-size: $font-size-medium;
    @include respond(phone) {
      font-size: $font-size-medium-mobile;
    }
  }
  &__name {
    display: inline-block;
    color: $color-text-primary;
    font-family: "Cormorant", serif;
    align-self: flex-end;
    line-height: 1;
  }

  &__price {
    display: inline-block;
    color: $color-text-primary;
    font-size: 1.2vw;
    margin-left: auto;
    align-self: flex-end;

    @include respond(phone) {
      font-size: 3.2vw;
    }
  }

  .button-container {
    height: 15vw;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;

    .custom-button {
      position: absolute;
      bottom: -0.8rem;
      right: 50%;
      z-index: 3;
      opacity: 0;
      margin-bottom: 1.2rem;
      background-color: white;
      color: #343433;
      width: 60%;
      border-radius: 0;
      height: 15%;
      transform: translateX(50%);
      font-size: 1vw;
      transition: all 0.3s;
    }
  }
}
