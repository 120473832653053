*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  background: #000;
}

$numOfParts: 4;
$animTime: 1s;
$stagger: 0.08s;
$sliderReadyTrans: all $animTime/2 $animTime;
$maxLettersStagger: 6;
$letterStagger: 0.1s;

.slider {
  overflow: hidden;
  position: relative;
  height: 100vh;
  color: #fff;

  @mixin sliderReady {
    .slider.s--ready & {
      @content;
    }
  }

  &__top-heading {
    z-index: $numOfParts*3;
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    transition: $sliderReadyTrans;
    transform: translateY(-30px);
    opacity: 0;

    @include sliderReady {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__slides {
    position: relative;
    height: 100%;
  }

  &__slide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &.s--active {
      pointer-events: auto;
    }

    @mixin slidePrev {
      .slider__slide.s--prev & {
        @content;
      }
    }

    @mixin slideActive {
      .slider__slide.s--active & {
        @content;
      }
    }

    &-content {
      z-index: $numOfParts + 2;
      ;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      text-transform: uppercase;
      line-height: 1;
    }

    @mixin subTextsActiveSlide {
      opacity: 0;
      transition: $animTime/2;

      @include slideActive {
        transition-delay: $animTime*0.65;
        opacity: 1;
        transform: translateY(0);
      }
    }

    /* General Styles for Subheading and Heading */
    &-subheading {
      font-size: clamp(16px, 2vw, 24px);
      $fontSize:clamp(16px, 2vw, 24px);
      opacity: 0;
      /* Responsive font scaling */
      letter-spacing: 2px;
      margin-bottom: 15px;
      transform: translateY(20px);
      text-align: center;
      word-break: break-word;
      /* Prevents overflow */

      @mixin slidePrev {
          .slider__slide.s--prev & {
            @content;
          }
        }
      
        @mixin slideActive {
          .slider__slide.s--active & {
            @content;
          }
        }

      @include slidePrev {
          transform: translateY($fontSize)
        }
      
        @include slideActive {
          opacity: 1;
          transform: translateY(0);
        }
    }

    &-heading {
      $fontSize: clamp(24px, 5vw, 60px);
      font-size: clamp(24px, 5vw, 60px);
      /* Responsive font scaling */
      letter-spacing: clamp(4px, 1.5vw, 12px);
      margin-bottom: 15px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      span {
        display: inline-block;
        opacity: 0;
        transform: translateY(-60px);
        transition: all 0.3s ease-in-out;
        white-space: nowrap;

        @include slidePrev {
          transform: translateY($fontSize)
        }

        @include slideActive {
          opacity: 1;
          transform: translateY(0);
        }

        @for $i from 1 through $maxLettersStagger {
          &:nth-child(#{$i}) {
            $delay: $letterStagger * ($i - 1);

            transition-delay: $delay;

            @include slideActive {
              transition-delay: $delay + $animTime/3;
            }
          }
        }

        &:nth-child(n+#{$maxLettersStagger + 1}) {
          $delay: $letterStagger * $maxLettersStagger;

          transition-delay: $delay;

          @include slideActive {
            transition-delay: $delay + $animTime/3;
          }
        }
      }
    }



    &-readmore {
      position: relative;
      font-size: 14px;
      text-transform: lowercase;
      backface-visibility: hidden;
      transform: translateY(-20px);
      cursor: pointer;

      @include subTextsActiveSlide;

      &:before {
        content: '';
        position: absolute;
        left: -2px;
        top: -3px;
        width: calc(100% + 4px);
        height: calc(100% + 6px);
        background: rgba(255, 255, 255, 0.4);
        transform: scaleX(0.3);
        transform-origin: 0 50%;
        transition: transform 0.3s;
      }

      &:hover:before {
        transform: scaleX(1);
      }
    }

    &-parts {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      width: 100%;
      height: 100%;

      &:after {
        content: '';
        z-index: $numOfParts + 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
      }
    }

    &-part {
      $partW: (
        100vw / $numOfParts
      );

    position: relative;
    width: percentage(1 / $numOfParts);
    height: 100%;

    $partRef: &;
    $imageFadeAT: $animTime/4;

    &-inner {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;
      background-size: 0 0;
      background-repeat: no-repeat;
      transition: transform $animTime/2 ease-in-out;

      &:before {
        content: '';
        position: absolute;
        width: 100vw;
        height: 100%;
        background-image: inherit;
        background-size: cover;
        background-position: center center;
        transition: opacity $imageFadeAT;
        opacity: 0;
      }

      @for $i from 1 through $numOfParts {
        #{$partRef}:nth-child(#{$i}) & {
          $delayOut: (
            $numOfParts - $i) * $stagger;
          $delayIn: $i * $stagger + $animTime/5;

          z-index: $numOfParts - $i;
          transition-delay: $delayOut;
          transform: translateX(percentage($i / $numOfParts * -1.3)
          );

        @include slideActive {
          transition-delay: $delayIn;
        }

        &:before {
          left: $partW * ($i - 1) * -1;
          transition-delay: $delayOut + $imageFadeAT/2;

          @include slideActive {
            transition-delay: $delayIn;
          }
        }
      }
    }

    @include slideActive {
      transform: translateX(0);
      transition-timing-function: ease;

      &:before {
        opacity: 1;
      }
    }
  }
}
}

&__control {
  $size: 50px;

  z-index: 100;
  position: absolute;
  left: 50px;
  top: 50%;
  width: $size;
  height: $size;
  margin-top: $size/-2;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  transform: translateX($size*-1);
  opacity: 0;
  transition: $sliderReadyTrans;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    margin-top: -10px;
    border: 2px solid #000;
    border-bottom: none;
    border-right: none;
    transform: translateX(5px) rotate(-45deg);
  }

  &--right {
    left: auto;
    right: 50px;
    transform: translateX($size);

    &:before {
      transform: translateX(-5px) rotate(135deg);
    }
  }

  @include sliderReady {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .slider {
    height: 100vh;

    &__top-heading {
      font-size: 12px;
      top: 50px;
    }

    &__slide {
      &-content {
        padding: 20px;
      }

      &-subheading {
        font-size: 18px;
        $fontSize:18px;
        opacity: 0;
        /* Slightly larger on smaller tablets */
        margin-bottom: 10px;
        @mixin slidePrev {
            .slider__slide.s--prev & {
              @content;
            }
          }
        
          @mixin slideActive {
            .slider__slide.s--active & {
              @content;
            }
          }
        @include slidePrev {
            transform: translateY($fontSize)
          }
        
          @include slideActive {
            opacity: 1;
            transform: translateY(0);
          }
      }

      &-heading {
        font-size: 32px;
        letter-spacing: 6px;
        margin-bottom: 10px;

        span {
          transform: translateY(-32px);
          /* Adjust translation for smaller fonts */
        }
      }

      &-readmore {
        font-size: 12px;
      }

      &-parts {
        flex-direction: row;

        &-part {
          width: 100%;
          height: 50%;
        }
      }
    }

    &__control {
      width: 40px;
      height: 40px;
      // left: 20px;

      // &--right {
      //   right: 20px;
      // }
    }
  }
}

@media (max-width: 480px) {
  .slider {
    &__top-heading {
      font-size: 10px;
      top: 30px;
    }

    &__slide {
      &-subheading {
        font-size: 14px;
        $fontSize:14px;
        opacity: 0;
@mixin slidePrev {
    .slider__slide.s--prev & {
      @content;
    }
  }

  @mixin slideActive {
    .slider__slide.s--active & {
      @content;
    }
  }
        @include slidePrev {
            transform: translateY($fontSize)
          }
        
          @include slideActive {
            opacity: 1;
            transform: translateY(0);
          }
      }

      &-heading {
        font-size: 24px;
        letter-spacing: 4px;
      }

      &-readmore {
        font-size: 10px;
      }

      &-heading span {
        transform: translateY(-24px);
        /* Align translation with text size */
      }
    }

    &__control {
      width: 30px;
      height: 30px;
    }
  }
}
}

.icon-link {
  z-index: 100;
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 32px;

  img {
    width: 100%;
    vertical-align: top;
  }

  &--twitter {
    left: auto;
    right: 5px;
  }
}