@import "../../scss/variables.styles.scss";
@import "../../scss/mixins.scss";

// Global styling for the video container and video element
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px; // For desktop, you can adjust this size
  margin: 0 auto;
  padding: 16px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background: #f3f2f2; // Light background for contrast
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  // Add hover effect for desktop
  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  }

  // For mobile responsiveness
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    padding: 10px;
  }
}

.video-element {
  width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 1s ease, transform 0.5s ease;
  border-radius: 6px;

  // Show video smoothly once loaded
  &.loaded {
    opacity: 1;
    transform: scale(1);
  }

  // Video controls styling for a better appearance
  ::-webkit-media-controls-panel {
    background-color: rgba(0, 0, 0, 0.7);
  }

  // Styling for mobile
  @media only screen and (max-width: 768px) {
    border-radius: 4px;
  }
}