@import "../../scss/variables.styles.scss";
.custom-button {
  outline: none;
  height: 2vw;
  width: 100%;

  border: 1px solid $color-black;
  background-color: transparent;
  color: $color-black;
  margin-bottom: 1vw;

  @include respond(phone) {
    height: 8vw;
    margin-bottom: 2vw;
  }
  cursor: pointer;
  &:hover {
    background-color: white;
    color: black;
  }

  & + .google-sign-in {
    margin: 1vw 0 0 0;
    color: $color-black;
    @include respond(phone) {
      margin: 3vw 0 0 0;
    }
    .container img {
      width: 1.2rem;
      vertical-align: middle;
      margin-right: 1rem;
    }
    .btn-txt {
      vertical-align: middle;
    }
  }
}
